/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import PDF from "react-pdf-js-infinite"
import IRPageWrapper from "../../../../layouts/IRPageWrapper"
import IRNav from "../../../../components/IRNav"
import IRBG from "../../../../components/IRBG"
import SubsidariesNav from "../../../../components/SubsidariesNav"
import Index2016 from "./subsidaries-2016.pdf"
import HomeLogo from "../../../../components/HomeLogo"

class SubsidariesPage extends Component {
  render() {
    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>General Corporate Information</title>
        </Helmet>
        <section className="hero is-small">
          <div className="columns">
            <div className="column is-offset-1">
              <div className="section">
                <HomeLogo />

                <h1 className="title" id="title-line">
                  Our Subsidaries
                </h1>
                <IRNav />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <SubsidariesNav />
          <div id="pdf-scroll">
            <PDF file={Index2016} scale={1.5} />
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

SubsidariesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default SubsidariesPage
